<!-- Mto particular de Datos de Funerarias Sucursales -->

<template>
  <div class="funes_M_datos conflex"
       style="justify-content:center" >
    <div class="column" style="width:600px">
      <div class="cab">TELÉFONOS/FAX</div>
      <v-sheet v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <!--       
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.pre.value"
              :label="schema.ctrls.pre.label"
              :disabled="!is_edit()"
            >
            </v-text-field>
          -->

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.tlf1.value"
            :label="schema.ctrls.tlf1.label"
            :disabled="apdsEdit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.tlf2.value"
            :label="schema.ctrls.tlf2.label"
            :disabled="apdsEdit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.tlf3.value"
            :label="schema.ctrls.tlf3.label"
            :disabled="apdsEdit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.tlf4.value"
            :label="schema.ctrls.tlf4.label"
            :disabled="apdsEdit">
          </v-text-field>
        </div>

        <!-- Segunda fila de telefonos -->
        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.tlf5.value"
            :label="schema.ctrls.tlf5.label"
            :disabled="apdsEdit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.tlf6.value"
            :label="schema.ctrls.tlf6.label"
            :disabled="apdsEdit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.fax1.value"
            :label="schema.ctrls.fax1.label"
            :disabled="apdsEdit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.fax2.value"
            :label="schema.ctrls.fax2.label"
            :disabled="apdsEdit">
          </v-text-field>
        </div>
      </v-sheet>

      <div class="cab">CONTACTO</div>
      <v-sheet v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.contratador.value"
            :label="schema.ctrls.contratador.label"
            :disabled="apdsEdit">
          </v-checkbox>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.cont1.value"
            :label="schema.ctrls.cont1.label"
            :disabled="apdsEdit">
          </v-text-field>
        </div>

        <v-text-field
          v-bind="$input"
          v-model="schema.ctrls.cont3.value"
          :label="schema.ctrls.cont3.label"
          :disabled="apdsEdit">
        </v-text-field>

        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.email1.value"
            :label="schema.ctrls.email1.label"
            :disabled="apdsEdit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.email2.value"
            :label="schema.ctrls.email2.label"
            :disabled="apdsEdit">
          </v-text-field>
        </div>

        <ctrlurl :schema="schema.ctrls.url" :edicion="is_edit()"></ctrlurl>
      </v-sheet>

      <!-- FIN COLUMNA IZQUIERDA -->
    </div>

    <!-- COLUMNA DERECHA -->
    <div class="column" style="width:600px">
      <div class="cab">ADMINISTRACIÓN</div>
      <v-sheet v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.cont2.value"
            :label="schema.ctrls.cont2.label"
            :disabled="apdsEdit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.admonemail.value"
            :label="schema.ctrls.admonemail.label"
            :disabled="apdsEdit">
          </v-text-field>
        </div>

        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.admontlf1.value"
            :label="schema.ctrls.admontlf1.label"
            :disabled="apdsEdit">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.admonfax1.value"
            :label="schema.ctrls.admonfax1.label"
            :disabled="apdsEdit">
          </v-text-field>
        </div>

        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.ban.value"
            :label="schema.ctrls.ban.label"
            :disabled="apdsEdit">
          </v-text-field>

          <uiText                            
            v-bind="$input"
            v-model="schema.ctrls.cta.value"
            :label="schema.ctrls.cta.label"
            :disabled="apdsEdit"
            format="banco">
          </uiText>

          <!-- <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.cta.value"
            :label="schema.ctrls.cta.label"
            :disabled="apdsEdit">
          </v-text-field> -->
        </div>
      </v-sheet>

      <div class="cab">OFICINA COMERCIAL</div>
      <v-sheet v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-checkbox
            v-bind="$checkbox"
            v-model="schema.ctrls.oficom.value"
            :label="schema.ctrls.oficom.label"
            :disabled="!is_edit()">
          </v-checkbox>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.vname.value"
            :label="schema.ctrls.vname.label"
            :disabled="!is_edit()">
          </v-text-field>
        </div>
        <div style="display:flex">
          <div style="flex: 1 1 70%">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.vdir.value"
              :label="schema.ctrls.vdir.label"
              :disabled="!is_edit()">
            </v-text-field>
          </div>
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.vtlf.value"
            :label="schema.ctrls.vtlf.label"
            :disabled="!is_edit()">
          </v-text-field>

          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.vfax.value"
            :label="schema.ctrls.vfax.label"
            :disabled="!is_edit()">
          </v-text-field>
        </div>
      </v-sheet>

      <div class="cab">DOTACIÓN</div>
      <v-sheet style="width:500px" v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <v-text-field
            v-bind="$input"
            v-model="schema.ctrls.tana.value"
            :label="schema.ctrls.tana.label"
            :disabled="!is_edit()">
          </v-text-field>
         <v-text-field 
            v-bind="$input"
            v-model="schema.ctrls.sala.value"
            :label="schema.ctrls.sala.label"
            :disabled="!is_edit()">
          </v-text-field>

          <!-- <v-input_n
            v-bind="$input"
            simbolo=''
            v-model="schema.ctrls.sala.value"
            :label="schema.ctrls.sala.label"
            :disabled="!is_edit()">
          </v-input_n> -->

           <v-text-field 
            v-bind="$input"

            v-model="schema.ctrls.cemen.value"
            :label="schema.ctrls.cemen.label"
            :disabled="!is_edit()">
          </v-text-field >

          <v-text-field 
            v-bind="$input"
            v-model="schema.ctrls.horno.value"
            :label="schema.ctrls.horno.label"
            :disabled="!is_edit()">
          </v-text-field >
        </div>
        <div style="display:flex">
         <v-text-field 
            v-bind="$input"
            v-model="schema.ctrls.auto.value"
            :label="schema.ctrls.auto.label"
            :disabled="!is_edit()">
         </v-text-field >

         <v-text-field 
            v-bind="$input"
            v-model="schema.ctrls.furgon.value"
            :label="schema.ctrls.furgon.label"
            :disabled="!is_edit()">
          </v-text-field >

        <v-text-field 
            v-bind="$input"
            v-model="schema.ctrls.frig.value"
            :label="schema.ctrls.frig.label"
            :disabled="!is_edit()">
         </v-text-field >

          <v-text-field 
            v-bind="$input"
            v-model="schema.ctrls.salap.value"
            :label="schema.ctrls.salap.label"
            :disabled="!is_edit()">
         </v-text-field >

        </div>
      </v-sheet>
    </div>
  </div>
</template>



<script>
  
  import { mixinCtrls } from "@/mixins/mixinCtrls";
  import plugs from "@/common/general_plugs";
  const ctrlurl = () => plugs.groute("ctrlurl.vue", "comp");

  export default {
    mixins: [mixinCtrls],
    components: { ctrlurl },
    props: {
      padre: { type:String, default: '' },      
      componenteTipo: { type:String, default:'M' },      
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema:null
      }
    },

    
    created() {
      this.ini_data();
    },


    computed: {
      apdsEdit() {
        if  (this.app=='2') return true;
        return !this.is_edit();
      }
    },


    methods: {
      ini_data() {        
        // guardo del store en variables locales
        this.schema = this.$store.state[this.padre].schema;  
      },


      // devuelvo si estamos en modo edición en el Mto
      is_edit() {   
        return this.$store.getters[this.padre + '/stEdicion'];        
      }
    }
  };
  
</script>
